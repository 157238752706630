// Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Services
import DashboardService     from 'services/DashboardService';
import UserService          from 'services/UserService';
import GlobalStateService from 'services/GlobalStateService';

// Components
import FloomlyComponent         from 'components/FloomlyComponent';
import Loader               from 'components/reusable/Loader';
import DashboardOverview    from 'components/pages/dashboard/DashboardOverview';
import PerfectClientRatingHearts from 'components/pages/dashboard/PerfectClientRatingHearts';

//-------------------------------------------------------------------------------------------------------------------

class DashboardByStylist extends FloomlyComponent {

    //--------------------------------------------------------------------------------------------------------------------
    //  Logic
    //--------------------------------------------------------------------------------------------------------------------

    constructor(props) {
        super(props);

        this.state = {
            dashboard: null,
            stylistUserID: null,
            stylists: null
        }
    }

    async componentDidMount() {
        let stylists = await UserService.listDiary();
        const loginDetails = GlobalStateService.getValue('loginDetails');

        // Only show me
        if (!loginDetails.permissions['TeamHubAll']) {
            stylists = stylists.filter(s => s.userID == loginDetails.user.userID);
        }

        this.setState({
            stylists
        }, () => {
            if (stylists.length > 0) {
                this.selectStylist(stylists[0].userID);
            }
        });
    }
    
    selectStylist(stylistUserID) {
        this.setState({
            stylistUserID,
            stylist: this.state.stylists.find(s => s.userID == stylistUserID)
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    
    render() {
        const {
            dashboard,
            stylists,
            stylist
        } = this.state;
        const {
            dashboardType
        } = this.props;

        if (!stylist) {
            return (<Loader />);
        }
        const loginDetails = GlobalStateService.getValue('loginDetails');

        return (<>

            <div className="dashboard-content-level-three">

                <div className="dashboard-by-stylist-row">

                    <div className="dashboard-by-stylist-header">

                        {loginDetails.permissions['TeamHubAll'] &&
                            <div className="mobile-only stylist-dropdown">
                                <select
                                    value={stylist?.userID}
                                    onChange={e => this.selectStylist(e.target.value)}
                                >
                                    {stylists.map((stylist, index) =>
                                        <option key={stylist.userID} value={stylist.userID}>
                                            {stylist.nickname}
                                        </option>
                                    )}
                                </select>
                            </div>
                        }

                        <div className="sticky-top desktop-only">

                            {dashboard ?
                                <div className="panel stylist-info-panel">
                                    <div className="panel-body">
                                        <div className="stylist-dashboard-stylist-info">
                                            <span className="user-name">
                                                {stylist.firstName} {stylist.lastName}
                                            </span>
                                            <span className="user-rating">
                                                <PerfectClientRatingHearts dashboard={dashboard} />
                                            </span>
                                        </div>
                                    </div>
                                </div> :
                                <Loader />
                            }

                            {loginDetails.permissions['TeamHubAll'] &&
                                <div className="panel stylist-list-panel">
                                    <div className="panel-header">
                                        Team Dashboard:
                                    </div>
                                    <div className="panel-body">

                                        <ul className="list">
                                            {stylists.map((stylist, index) => {
                                                return (
                                                    <li key={index} onClick={() => this.selectStylist(stylist.userID)}>
                                                        {/*<img className="user-icon list-item-icon" src=userIconImage />*/}
                                                        <div className="list-item-name">
                                                            {stylist.nickname}
                                                        </div>
                                                        <div className="list-item-colour-bar" style={{ backgroundColor: stylist.diaryColour }}></div>
                                                    </li>
                                                );
                                            })}
                                        </ul>

                                    </div>
                                </div>
                            }

                        </div>

                    </div>

                    <div className="dashboard-by-stylist-content">

                        <DashboardOverview
                            dashboardType={dashboardType}
                            stylistUserID={stylist.userID}
                            onLoad={dashboard => this.setState({ dashboard })}
                        />

                    </div>

                </div>

            </div>
        </>);
    }

}

export default withRouter(DashboardByStylist);