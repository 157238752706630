// Libs
import React from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';

// Services & Helpers
import DiaryService from 'services/DiaryService';
import ServiceService from 'services/ServiceService';
import CustomerService from 'services/CustomerService';
import ProductService from 'services/ProductService';
import SalonService from 'services/SalonService';
import SearchService from 'services/SearchService';
import LoyaltySchemeService from 'services/LoyaltySchemeService';
import GlobalStateService from 'services/GlobalStateService';
import BootboxHelper from 'helpers/BootboxHelper';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import Loader from 'components/reusable/Loader';
import Search from 'components/reusable/Search';
import Money from 'components/reusable/Money';

//-------------------------------------------------------------------------------------------------------------------

class CheckOutPanel extends FloomlyComponent {

    constructor(props) {
        super(props);

        this.state = {
            checkOut: props.checkOut,
            loyaltyScheme: props.loyaltyScheme,
            isAddingService: false,
            isAddingPurchase: false,
            isAddingLinkedAppointment: false,
            recentNotCheckedOutAppointments: null,
            isAddingTips: false,
            isEditingDiscount: false,
        }

        this.clientInfo = GlobalStateService.getValue('clientInfo');

        this.toggleEditingDiscount = this.toggleEditingDiscount.bind(this);

        this.serviceSequenceID = -1;
    }

    async updateFields(values) {
        const checkOut = { ...this.state.checkOut };

        for (var field in values) {
            let value = values[field];

            switch (field) {
                case 'appointmentServices':
                case 'appointmentPackages':
                    this.isScheduleChanged = true;
                    break;
                case 'discountType':
                    if (value === 'percentage' || value === 'amount') {
                        if (checkOut.appointmentServices.every(asv => !asv.isDiscounted)) {
                            checkOut.appointmentServices.forEach(asv => {
                                asv.isDiscounted = true;
                            });
                        }
                    } else if (!value) {
                        checkOut.discountTotal = 0;
                        checkOut.discountAmount = 0;
                        checkOut.discountReason = null;
                    }
                    break;
                case 'discountAmount':
                    if (value < 0) {
                        value = -value;
                    }
                    break;
            }

            checkOut[field] = value;
        }

        DiaryService.updateTotals(checkOut);
        DiaryService.updateBalance(checkOut);
        this.props.onCheckOutChanged(checkOut);
        this.setState({
            checkOut
        });
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Retail stock item
    //--------------------------------------------------------------------------------------------------------------------

    async selectRetailStockItem(stockItem) {
        // Get more info about stock item
        stockItem = await ProductService.getStockItem(stockItem.id);

        // Stylist is the last stylist on the appt
        // If this appt has no services then take the first stylist's ID
        const appointmentServices = this.state.checkOut.appointmentServices || [];
        const stylistUserID = (appointmentServices.length > 0 ? appointmentServices[appointmentServices.length - 1].stylistUserID : this.clientInfo.users[0].userID);

        // Add stock item as purchase
        const appointmentPurchases = [...this.state.checkOut.appointmentPurchases || []];
        const appointmentPurchase = {
            appointmentPurchaseID: this.purchaseSequenceID--,
            stylistUserID: stylistUserID,
            stockItemID: stockItem.id,
            stockItem: stockItem,
            total: stockItem.sellPrice
        };
        appointmentPurchases.push(appointmentPurchase);

        await this.props.onChange('appointmentPurchases', appointmentPurchases);
        await this.updateFields({ appointmentPurchases });

        this.setState({
            isAddingPurchase: false
        });
    }

    async updateAppointmentPurchaseField(index, field, value) {
        const appointmentPurchases = [...this.state.checkOut.appointmentPurchases];
        appointmentPurchases[index][field] = value;
        await this.updateFields({ appointmentPurchases: appointmentPurchases });
        await this.props.onChange('appointmentPurchases', appointmentPurchases);

    }

    confirmRemovePurchase(index) {
        // TODO show confirmation box
        this.removePurchase(index);
    }

    removePurchase(index) {
        const appointmentPurchases = [...this.state.checkOut.appointmentPurchases]
        appointmentPurchases.splice(index, 1);
        this.updateFields({ appointmentPurchases: appointmentPurchases });
        this.props.onChange('appointmentPurchases', appointmentPurchases);
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Services
    //--------------------------------------------------------------------------------------------------------------------

    async addNewService(service, serviceCategory) {
        const appointmentServices = [...this.state.checkOut.appointmentServices || []];

        // Look up service details
        const serviceDetails = await ServiceService.get(service.id);

        // Stylist is the last stylist on the appt
        // If this appt has no services then take the first stylist's ID
        const stylistUserID = (appointmentServices.length > 0 ? appointmentServices[appointmentServices.length - 1].stylistUserID : this.clientInfo.users[0].userID);

        // Get price for this stylist
        const price = this.props.getServicePrice(parseInt(service.id), stylistUserID);
        
        // Add service
        const appointmentService = {
            appointmentServiceID: this.serviceSequenceID--,
            appointmentID: 0,
            stylistUserID: stylistUserID,
            salesUserID: stylistUserID,
            service: serviceDetails,
            serviceCategory: serviceCategory,
            total: price,
            appointmentServiceUserCommissions: [{ stylistUserID: stylistUserID, commissionProportion: 1 }]
        };
        appointmentServices.push(appointmentService);

        this.updateFields({ appointmentServices });

        await this.props.onChange('appointmentServices', appointmentServices);

        this.setState({
            isAddingService: false
        });
    }

    async updateAppointmentPackageField(id, field, value) {
        const appointmentPackages = [...this.state.checkOut.appointmentPackages];
        const index = appointmentPackages.findIndex(ap => ap.appointmentPackageID == id);
        appointmentPackages[index][field] = value;
        await this.updateFields({ appointmentPackages: appointmentPackages });
        await this.props.onChange('appointmentPackages', appointmentPackages);
    }

    //confirmRemovePackage(index) {
    //    //TODO!!! MISSING!!!
    //}

    async updateAppointmentServiceFields(index, values) {
        const appointmentServices = [...this.state.checkOut.appointmentServices];
        const apptService = appointmentServices[index];
        for (let fieldName in values) {
            const value = values[fieldName];
            apptService[fieldName] = value;
            if (fieldName == 'stylistUserID') {
                apptService.total = this.props.getServicePrice(apptService.service.serviceID, parseInt(value));
            }
        }
        await this.updateFields({ appointmentServices });
        await this.props.onChange('appointmentServices', appointmentServices);
    }    

    async confirmRemoveService(index) {
        const confirm = await BootboxHelper.confirm('Are you sure you want to remove this service?');
        if (confirm) {
            this.removeService(index);
        }
    }

    async removeService(index) {
        const appointmentServices = [...this.state.checkOut.appointmentServices]
        appointmentServices.splice(index, 1);
        await this.updateFields({ appointmentServices: appointmentServices });
        await this.props.onChange('appointmentServices', appointmentServices);
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Linked Appointment
    //--------------------------------------------------------------------------------------------------------------------

    async startAddingLinkedAppointment() {
        this.setState({
            isAddingLinkedAppointment: true
        })
    }

    async selectLinkedAppointmentCustomer(customer) {
        const { checkOut } = this.state;
        this.setState({
            customer
        });
        const ignoreIDs = [
            checkOut.appointmentID
        ];
        if (checkOut.linkedAppointments) {
            checkOut.linkedAppointments.forEach(appt => ignoreIDs.push(appt.appointmentID));
        }
        const recentNotCheckedOutAppointments = await DiaryService.listRecentNotCheckedOutAppointments(customer.id, ignoreIDs, checkOut.date);
        this.setState({
            recentNotCheckedOutAppointments
        });
    }

    async addLinkedAppointment(appt) {
        const linkedAppointments = [...this.state.checkOut.linkedAppointments || []];
        linkedAppointments.push(appt);
        this.updateFields({
            linkedAppointments
        });

        await this.props.onChange('linkedAppointments', linkedAppointments);
        this.setState({
            isAddingLinkedAppointment: false,
            recentNotCheckedOutAppointments: null
        });
    }

    confirmRemoveLinkedAppointment(index) {
        this.removeLinkedAppointment(index);
    }

    async removeLinkedAppointment(index) {
        const linkedAppointments = [...this.state.checkOut.linkedAppointments || []];
        linkedAppointments.splice(index, 1);
        this.updateFields({
            linkedAppointments
        });

        await this.props.onChange('linkedAppointments', linkedAppointments);
    }

    getPackageName(packageId) {
        let appointmentPackage = this.state.checkOut.appointmentPackages.find(ap => ap.appointmentPackageID == packageId);
        return appointmentPackage.package.name;
    }
    //--------------------------------------------------------------------------------------------------------------------
    // Loyalty scheme
    //--------------------------------------------------------------------------------------------------------------------

    renderLoyaltyScheme() {
        const {
            loyaltyScheme,
            checkOut
        } = this.state;

        if (!loyaltyScheme || !loyaltyScheme.isActive || checkOut.status == 'checkedOut') {
            return null;
        }

        let pointsEarned = 0;
        if (!checkOut.discountType) {
            pointsEarned = LoyaltySchemeService.getPointsEarned(loyaltyScheme, checkOut.appointmentServices, checkOut.appointmentPurchases);
        }

        if (pointsEarned <= 0) {
            return null;
        }

        return (
            <div className="panel check-out-loyalty-scheme-panel">
                <div className="panel-header">
                    Loyalty scheme
                </div>
                <div className="panel-body">
                    {checkOut.customer.firstName} will earn up to <span>{pointsEarned}</span>{' '}
                    loyalty point{pointsEarned == 1 ? '' : 's'} for this check out.
                </div>
            </div>
        );
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Discount
    //--------------------------------------------------------------------------------------------------------------------

    toggleEditingDiscount() {
        this.setState({
            isEditingDiscount: !this.state.isEditingDiscount
        });
        if (!this.state.checkOut.discountType) {
            this.updateFields({
                discountType: 'percentage'
            });
        }
    }

    //-------------------------------------------------------------------------------------------------------------------
    // Promo Code
    //-------------------------------------------------------------------------------------------------------------------
    removePromoCode() {
        const { checkOut } = this.state;
        checkOut.appliedPromoCode = null;
        this.updateFields({ promoCodeID: null });
    }

    //--------------------------------------------------------------------------------------------------------------------
    // Stylist Commission
    //--------------------------------------------------------------------------------------------------------------------

    async addStylistCommission(index, apptServiceID) {
        const appointmentServices  = [...this.state.checkOut.appointmentServices];
        const apptService = appointmentServices.find(as => as.appointmentServiceID == apptServiceID);
        const apptServUserComm = apptService.appointmentServiceUserCommissions[index];
        apptServUserComm.commissionProportion = this.props.commissions[0].commissionPercentage;

        //add new stylist commission , if first stylist is same for first commission choose other
        const userCommission = {
            appointmentServiceID: apptService.appointmentServiceID,
            commissionProportion: 1 - apptServUserComm.commissionProportion,
            stylistUserID: apptServUserComm.stylistUserID == this.clientInfo.users[0].userID ? this.clientInfo.users[1].userID : this.clientInfo.users[0].userID
        };
        apptService.appointmentServiceUserCommissions.push(userCommission);

       this.updateFields({ appointmentServices });
       await this.props.onChange('appointmentServices', appointmentServices);
    }

    async changeStylistCommission(index, apptServiceID, value) {
        const  appointmentServices  = [...this.state.checkOut.appointmentServices];
        const apptService = appointmentServices.find(as => as.appointmentServiceID == apptServiceID);
        apptService.appointmentServiceUserCommissions.map((userComm, comIndex) => {
            if (index == comIndex) {
                userComm.commissionProportion = value;
            }
            else {
                userComm.commissionProportion = 1 - value;
            }
        });
        this.updateFields({ appointmentServices });
        await this.props.onChange('appointmentServices', appointmentServices);
    }

    async confirmRemoveStylistCommission(index, apptServiceID) {
        const confirm = await BootboxHelper.confirm('Are you sure you want to remove this stylist commission?');
        if (confirm) {
            this.removeStylistCommission(index, apptServiceID);
        }
    }

    async removeStylistCommission(index, apptServiceID) {
        const appointmentServices = [...this.state.checkOut.appointmentServices];
        const apptService = appointmentServices.find(as => as.appointmentServiceID == apptServiceID);
        apptService.appointmentServiceUserCommissions.map((userComm, comIndex) => {
            if (index == comIndex) {
                apptService.appointmentServiceUserCommissions.splice(index, 1);
            }
            else {
                userComm.commissionProportion = 1;
            }
        });

        this.updateFields({ appointmentServices });
        await this.props.onChange('appointmentServices', appointmentServices);
    }

    async updateAppointmentServiceUserCommFields(index, apptServiceID, values) {
        const appointmentServices = [...this.state.checkOut.appointmentServices];
        const apptService = appointmentServices.find(as => as.appointmentServiceID == apptServiceID);
        const appointmentServiceUserCommissions = apptService.appointmentServiceUserCommissions || [];
        const apptServUserComm = appointmentServiceUserCommissions[index];
        for (let fieldName in values) {
            const value = values[fieldName];
            if (fieldName == 'stylistUserID') {
                var isStylistSelected = appointmentServiceUserCommissions.some(asu => asu.stylistUserID == value);
                if (isStylistSelected) {
                    BootboxHelper.alert(`Stylist already selected. Please select different stylist.`);
                    return;
                }
            }
            apptServUserComm[fieldName] = value;
        }
        await this.updateFields({ appointmentServices });
        await this.props.onChange('appointmentServices', appointmentServices);
    }
    //--------------------------------------------------------------------------------------------------------------------
    // Render
    //--------------------------------------------------------------------------------------------------------------------

    renderServices() {
        const {
            isAddingService,
            checkOut
        } = this.state;
        let lastApptService = null;
        
        return (<>
            <div className="panel-info-header">
                Services
            </div>

            {/* Services */}
            <ul className="list service-list">

                {checkOut.appointmentServices && checkOut.appointmentServices.map((apptService, index) => {
                    const content = (
                        <React.Fragment key={index + '-' + apptService.appointmentServiceID}>
                            {this.renderApptService(apptService, index, lastApptService)}
                        </React.Fragment>
                    );
                    lastApptService = apptService;
                    return content;
                })}

            </ul>

            {/* Service search */}
            {isAddingService &&
                <div className="service-search search-box-absolute">

                    <Search
                        className="search-box"
                        autoFocus={true}
                        placeholder="Search for a service..."
                        maxResults={50}
                        onBlur={e => this.setState({ isAddingService: false })}
                        search={async (query, setResults, maxResults, state) => {
                            const results = await SearchService.search(query, ['Service'], { maxResults, state });
                            setResults(results);
                        }}
                        renderResult={(result, srIndex, info) =>
                            <div key={srIndex} className="search-result list-item" onMouseDown={e => {
                                this.addNewService(result);
                                info.clearSearch();
                                info.focus();
                            }}>
                                <div className="list-item-name">
                                    {result.name}
                                </div>
                            </div>
                        }
                    />
                </div>
            }
            {!isAddingService && checkOut.status !== 'checkedOut' &&
                <button className="button button-tertiary search-box-button" onClick={e => this.setState({ isAddingService: true })} disabled={checkOut.status === 'checkedOut'}>
                    <span className="fa fa-plus"></span>{' '}
                    Add a service
                </button>
            }

        </>);
    }

    renderApptService(apptService, index, lastApptService) {
        const { checkOut } = this.state;
        const apptPackage = checkOut.appointmentPackages.find(ap => ap.appointmentPackageID == apptService.appointmentPackageID);
        const isFixedPricePackage = !!apptPackage && apptPackage.pricingType == 'fixed';
        const showPackageHeader = !!apptPackage && (!lastApptService || apptService.appointmentPackageID != lastApptService.appointmentPackageID);

        return (<>
            {showPackageHeader &&
                this.renderPackageHeader(apptPackage)
            }

            <li className="non-selectable">

                <span className={'service-list-icon ' + (!!apptService.appointmentPackageID && 'service-list-icon-indent')}>
                    <span className="fas fa-cut"></span>
                </span>

                <span className="service-list-name">
                    {apptService.service.name}
                </span>

                {!isFixedPricePackage &&
                    <span className="service-list-price">

                        <input type="number" value={apptService.total || ''} onChange={e => this.updateAppointmentServiceFields(index, { total: Number(e.target.value) || '' })} disabled={checkOut.status === 'checkedOut'} />

                    </span>
                }

                {!apptService.appointmentPackageID && !apptService.time && !checkOut.dateCheckedOut &&
                    <span className="floating-controls">

                        <button className="button customer-summary-change-button" onClick={e => this.confirmRemoveService(index)} disabled={checkOut.status === 'checkedOut'}>
                            <span className="fa fa-times"></span>
                        </button>

                    </span>
                }

            </li>

            {apptService.appointmentServiceUserCommissions && apptService.appointmentServiceUserCommissions.map((stylistCommission, commissionIndex) =>
                <li className="non-selectable" key={commissionIndex}>

                    <span className="service-list-icon" style={{ marginLeft: 15 }}>
                        <span className="fa fa-user" />
                    </span>

                    <span className="service-list-name" style={{ marginRight: 0 }}>
                        <select
                            value={stylistCommission.stylistUserID}
                            style={{ width: '100%' }}
                            onChange={e => this.updateAppointmentServiceUserCommFields(commissionIndex, apptService.appointmentServiceID, { stylistUserID: parseInt(e.target.value) })}
                        >
                            {
                                !apptService.time &&
                                <option value="">Salon Sales</option>
                            }
                            {this.clientInfo.users.map(u =>
                                <option key={u.userID} value={u.userID}>{u.nickname}</option>
                            )}
                        </select>
                    </span>
                    {this.props.isSplitCommissionEnabled && apptService.appointmentServiceUserCommissions.length <= 1 &&
                        <button className="button add-commission" onClick={() => this.addStylistCommission(commissionIndex, apptService.appointmentServiceID)}>
                            <span className="fa fa-plus" title="Add commission"></span>
                        </button>
                    }
                    {apptService.appointmentServiceUserCommissions.length > 1 && <>
                        <select
                            style={{ padding: '0px 5px' }}
                            value={stylistCommission.commissionProportion}
                            onChange={(e) => this.changeStylistCommission(commissionIndex, apptService.appointmentServiceID, e.target.value)}
                        >
                            {this.props.commissions.map(c =>
                                <option key={c.id} value={c.commissionPercentage}>
                                    {(c.commissionPercentage * 100).toFixed()}%
                                </option>
                            )}
                        </select>
                        {commissionIndex == 1 &&
                            <span className="floating-controls">
                                <button className="button commission-remove-button" onClick={e => this.confirmRemoveStylistCommission(commissionIndex, apptService.appointmentServiceID)} disabled={checkOut.status === 'checkedOut'}>
                                    <span className="fa fa-times"></span>
                                </button>
                            </span>
                        }
                    </>}
                </li>
            )}
        </>);
    }

    renderPackageHeader(apptPackage) {
        const id = apptPackage.appointmentPackageID;
        return (
            <li
                key={'Package-' + id}
                id={'Package-' + id}
                className="colour-coded-item service-list-package"
                style={{ borderLeft: '4px solid black' }}
                onMouseOver={e => this.highlightEvents(id)}
                onMouseOut={e => this.unhighlightEvents(id)}
            >

                <span className="service-list-icon">
                    <span className="fas fa-cubes"></span>
                </span>

                <span className="service-list-name">
                    {apptPackage.package.name}
                </span>

                {apptPackage.pricingType == 'fixed' &&
                    <span className="service-list-price">
                        <input
                            type="number"
                            value={apptPackage.total}
                            onChange={e => this.updateAppointmentPackageField(id, 'total', Number(e.target.value) || '')}
                        />
                    </span>
                }

                {/*
                <span className="floating-controls">

                    <button className="button customer-summary-change-button" onClick={e => this.confirmRemovePackage(id)}>
                        <span className="fa fa-times"></span>
                    </button>

                </span>
                */}

            </li>
        );
    }

    renderPurchases() {
        const {
            isAddingPurchase,
            checkOut
        } = this.state;

        return (<>

            <div className="panel-info-header">
                Retail
            </div>

            <ul className="list service-list">
                {checkOut.appointmentPurchases &&
                    checkOut.appointmentPurchases.map((apptPurchase, index) =>
                        <React.Fragment key={index}>
                            <li className="non-selectable">

                                <span className="service-list-icon">
                                    <span className="fas fa-cash-register"></span>
                                </span>

                                <span className="service-list-name">
                                    {apptPurchase.stockItem.product.name}
                                    {apptPurchase.stockItem.size > 0 &&
                                        <div>
                                            ({apptPurchase.stockItem.size}{apptPurchase.stockItem.product.stockUnit})
                                        </div>
                                    }
                                </span>

                                <span className="service-list-price">

                                    <input type="number" value={apptPurchase.total || ''} onChange={e => this.updateAppointmentPurchaseField(index, 'total', Number(e.target.value) || '')} disabled={checkOut.status === 'checkedOut'} />

                                </span>

                                <span className="floating-controls">

                                    <button className="button customer-summary-change-button" onClick={e => this.confirmRemovePurchase(index)} disabled={checkOut.status === 'checkedOut'}>
                                        <span className="fa fa-times"></span>
                                    </button>

                                </span>

                            </li>

                            <li className="non-selectable">

                                <span className="service-list-icon">
                                    <span className="fa fa-user" />
                                </span>

                                <span className="service-list-name" style={{ marginRight: 0 }}>
                                    <select
                                        value={apptPurchase.stylistUserID}
                                        style={{ width: '100%' }}
                                        onChange={e => this.updateAppointmentPurchaseField(index, 'stylistUserID', parseInt(e.target.value))}
                                        disabled={checkOut.status === 'checkedOut'}
                                    >
                                        {this.clientInfo.users.map(u =>
                                            <option key={u.userID} value={u.userID}>{u.nickname}</option>
                                        )}
                                    </select>
                                </span>

                            </li>

                        </React.Fragment>
                    )}
            </ul>

            {isAddingPurchase &&
                <div className="product-search search-box-absolute">
                    <Search
                        className="search-box"
                        placeholder="Search for a product..."
                        autoFocus={true}
                        maxResults={25}
                        onBlur={e => this.setState({ isAddingPurchase: false })}
                        search={async (query, setResults, maxResults, state) => {
                            const results = await SearchService.search(query, ['StockItem'], { filters: { stockUsage: 'Retail' }, maxResults, state });
                            setResults(results);
                        }}
                        renderResult={(result, srIndex, info) =>
                            <div key={srIndex} className="search-result list-item" onMouseDown={e => {
                                this.selectRetailStockItem(result);
                                info.clearSearch();
                                info.focus();
                            }}>
                                <div className="list-item-name" style={{ flex: 1 }}>
                                    {result.name}
                                    <div style={{ display: 'flex' }}>
                                        {!!result.size && result.size != '0' ? result.size : null}
                                        <div className="list-item-price">
                                            <Money amount={Number(result.sellPrice) || 0} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    />
                </div>
            }

            {!isAddingPurchase && checkOut.status !== 'checkedOut' &&
                <button className="button button-tertiary search-box-button" onClick={e => this.setState({ isAddingPurchase: true })} disabled={checkOut.status === 'checkedOut'}>
                    <span className="fa fa-plus"></span>{' '}
                    Add a retail purchase
                </button>
            }

        </>);
    }

    renderLinkedAppointments() {
        const {
            isAddingLinkedAppointment,
            recentNotCheckedOutAppointments,
            checkOut,
            customer
        } = this.state;

        return (<>

            <div className="panel-info-header">
                Other appointments
            </div>

            <ul className="list service-list">
                {checkOut.linkedAppointments &&
                    checkOut.linkedAppointments.map((appt, index) =>
                        <li key={index} className="non-selectable">

                            <span className="service-list-icon">
                                <span className="fas fa-calendar-alt"></span>
                            </span>

                            <span className="service-list-name clip-text">
                                {appt.customer.firstName} {appt.customer.lastName}
                                <br />
                                {moment(appt.date).format('DD/MM/YYYY')}<br />
                            </span>

                            <span className="service-list-price">

                                <Money amount={appt.balance} />

                            </span>

                            <span className="service-list-icon-right">

                                <button className="button customer-summary-change-button" onClick={e => this.confirmRemoveLinkedAppointment(index)} disabled={checkOut.status === 'checkedOut'}>
                                    <span className="fa fa-times"></span>
                                </button>

                            </span>

                        </li>
                    )}
            </ul>

            {/*
            {isAddingLinkedAppointment &&
                <Loader isInline={true} />
            }*/}

            {isAddingLinkedAppointment &&
                <div className="appointment-search search-box-absolute">

                    <Search
                        className="search-box"
                        autoFocus={true}
                        placeholder="Find customer..."
                        maxResults={50}
                        renderWrapper={(input, results, info) => (<>
                            {input}
                            {results && results.length > 0 &&
                                <div className={'list search-results ' + (info.isActive ? 'active' : '')}>
                                    {results}
                                </div>
                            }
                        </>)}
                        search={async (query, setResults, maxResults, state) => {
                            this.customerSearchQuery = query;
                            const results = await SearchService.search(query, ['Customer'], { maxResults, state });
                            setResults(results);
                        }}
                        renderResult={(customer, index) =>
                            <div key={index} className="search-result list-item" onMouseDown={e => this.selectLinkedAppointmentCustomer(customer)}>
                                <div className="list-item-name">
                                    {customer.name}
                                    <div className="list-subitem-number">
                                        {customer.customerTel} {customer.customerTel && customer.customerEmail && <>-</>} {customer.customerEmail}
                                    </div>
                                </div>
                            </div>
                        }
                    />

                </div>
            }

            {isAddingLinkedAppointment && customer && !recentNotCheckedOutAppointments &&
                <Loader isInline />
            }

            {isAddingLinkedAppointment && customer && recentNotCheckedOutAppointments && <>
                <div className="panel-info-header">
                    Appointments for {customer.name}
                </div>

                {recentNotCheckedOutAppointments.length == 0 ?
                    <p>
                        There are no recent open appointments
                    </p>
                    :
                    <ul className="list service-list">
                        {recentNotCheckedOutAppointments.map(appt =>
                            <li key={appt.appointmentID} onClick={e => this.addLinkedAppointment(appt)}>

                                <span className="service-list-icon">
                                    <span className="fas fa-calendar-alt"></span>
                                </span>

                                <span className="service-list-name clip-text">
                                    {appt.firstPackage ? appt.firstPackage.package.name : appt.firstService.service.name}
                                </span>

                                <span className="service-list-price">
                                    {moment(appt.date).format('DD/MM/YYYY')}<br />

                                    <Money amount={appt.balance} />

                                </span>

                            </li>
                        )}
                    </ul>
                }

            </>}

            {!isAddingLinkedAppointment && checkOut.status !== 'checkedOut' &&
                <button className="button button-tertiary search-box-button" onClick={e => this.startAddingLinkedAppointment()} disabled={checkOut.status === 'checkedOut'}>
                    <span className="fa fa-plus"></span>{' '}
                    Add an appointment
                </button>
            }

        </>);
    }

    renderDiscount() {
        const {
            isEditingDiscount,
            checkOut
        } = this.state;
        const currency = SalonService.getCurrentCurrency();

        return (<>
            <li className="non-selectable">

                <span className="service-list-icon">
                    <span className="fas fa-arrow-down"></span>
                </span>

                <span className="service-list-name">
                    Discount
                </span>

                <span className="service-list-icon">

                    <button className="button" onClick={this.toggleEditingDiscount}>
                        <span className={'fa ' + (checkOut.status === 'checkedOut' ? 'fa-eye': isEditingDiscount ? 'fa-arrow-left' : 'fa-pencil-alt')}></span>
                    </button>

                </span>

                <span className="service-list-price">

                    <Money amount={-checkOut.discountTotal} />

                </span>

            </li>

            {isEditingDiscount && <>

                <li className="non-selectable discount-row-1">

                    <span className="service-list-icon">

                    </span>

                    <span className="service-list-name">

                        <select value={checkOut.discountType || ''} onChange={e => this.updateFields({ discountType: e.target.value })} disabled={checkOut.status === 'checkedOut'}>
                            <option value="">(Select)</option>
                            <option value="amount">Fixed</option>
                            <option value="percentage">Percent</option>
                        </select>

                    </span>

                    <span className="service-list-price">

                        {checkOut.discountType == 'amount' &&
                            <NumberFormat
                                disabled={checkOut.status === 'checkedOut'}
                                thousandSeparator={currency.thousandSeparator}
                                decimalSeparator={currency.decimalSeparator}
                                prefix={currency.symbol}
                                placeholder={currency.symbol}
                                className="has-prefix"
                                value={checkOut.discountAmount || ''}
                                onValueChange={(values) => this.updateFields({ discountAmount: values.value })}
                            />
                        }
                        {checkOut.discountType == 'percentage' &&
                            <NumberFormat
                                disabled={checkOut.status === 'checkedOut'}
                                thousandSeparator={currency.thousandSeparator}
                                decimalSeparator={currency.decimalSeparator}
                                suffix="%"
                                className="has-suffix"
                                placeholder="%"
                                value={checkOut.discountAmount || ''}
                                onValueChange={(values) => this.updateFields({ discountAmount: values.value })}
                            />
                        }

                    </span>

                </li>

                {(checkOut.discountType === 'percentage' || checkOut.discountType === 'amount') && checkOut.appointmentServices.map((apptService, index) =>
                    <li key={index} className="non-selectable discount-row-1">

                        <span className="service-list-icon">

                        </span>

                        <span className="service-list-name">

                            {apptService.service.name}

                        </span>

                        <span className="service-list-price">

                            <input type="checkbox" checked={apptService.isDiscounted} onChange={e => this.updateAppointmentServiceFields(index, { isDiscounted: e.target.checked })} disabled={checkOut.status === 'checkedOut'} />

                        </span>

                    </li>
                )}

                <li className="non-selectable discount-row-2">

                    <span className="service-list-icon">

                    </span>

                    <span className="service-list-name">

                        <input
                            disabled={checkOut.status === 'checkedOut'}
                            type="text"
                            value={checkOut.discountReason || ''}
                            onChange={e => this.updateFields({ discountReason: e.target.value })}
                            placeholder="Reason for discount"
                        />

                    </span>

                </li>

                <li className="non-selectable discount-row-1">

                    <span className="service-list-icon">

                    </span>

                    <span className="service-list-name">

                        Stylist commission on full amount

                    </span>

                    <span className="service-list-price">

                        <input
                            disabled={checkOut.status === 'checkedOut'}
                            type="checkbox"
                            checked={checkOut.stylistCommissionFullAmount}
                            onChange={e => this.updateFields({ stylistCommissionFullAmount: e.target.checked })}
                        />

                    </span>

                </li>
            </>}
        </>);
    }

    renderPromoCodes() {
        const { checkOut } = this.state;

        return (<>
            <li className="non-selectable">
                <span className="service-list-icon">
                    <span className="fas fa-gift"></span>
                </span>

                {!checkOut.appliedPromoCode &&
                    <>
                        <span className="service-list-name">
                            Promo codes
                        </span>
                        <span className="service-list-price">
                            <select
                                value={checkOut.promoCodeID}
                                style={{ width: '100%' }}
                                onChange={e => this.updateFields({ promoCodeID: e.target.value })}
                            >
                                {<option value="">(Select)</option>}

                                {checkOut.promoCodes.map(pr =>
                                    <option key={pr.promoCodeID} value={pr.promoCodeID}>{pr.name} ({pr.code})</option>
                                )}
                            </select>
                        </span>
                    </>
                }
                {checkOut.appliedPromoCode && <>
                    <span className="service-list-name">
                        Promo code
                    </span>
                    <span className="service-list-price">
                        {checkOut.appliedPromoCode}
                    </span>
                    <span className="floating-controls">
                        {!checkOut.dateCheckedOut &&
                            <>
                                <button className="button customer-summary-change-button" onClick={e => this.removePromoCode()} disabled={checkOut.status === 'checkedOut'}>
                                    <span className="fa fa-times"></span>
                                </button>
                            </>
                        }
                    </span>
                </>
                }
            </li>
        </>)
    }

    render() {
        const {
            checkOut,
        } = this.state;

        // Sum value of linked appointments
        let linkedAppointmentsTotal = 0;
        if (checkOut.linkedAppointments) {
            checkOut.linkedAppointments.forEach(la => {
                linkedAppointmentsTotal += la.balance;
            });
        }

        return (<>

            <div className="panel check-out-panel">
                <div className="panel-header">
                    Check Out
                </div>
                <div className="panel-body">

                    {this.renderServices()}

                    {this.renderPurchases()}

                    {this.renderLinkedAppointments()}

                    <div className="panel-info-header">
                        Totals
                    </div>

                    <ul className="list service-list">

                        {/* Total Services */}
                        <li className="non-selectable">

                            <span className="service-list-icon">
                                <span className="fas fa-cut"></span>
                            </span>

                            <span className="service-list-name">
                                Total Services
                            </span>

                            <span className="service-list-price">
                                <Money amount={checkOut.serviceTotal} />
                            </span>

                        </li>

                        {/* Total Retail */}
                        <li className="non-selectable">

                            <span className="service-list-icon">
                                <span className="fas fa-cash-register"></span>
                            </span>

                            <span className="service-list-name">
                                Total Retail
                            </span>

                            <span className="service-list-price">
                                <Money amount={checkOut.retailTotal} />
                            </span>

                        </li>

                        {this.renderDiscount()}
                        {this.renderPromoCodes()}

                        {/* Other appointments */}
                        {linkedAppointmentsTotal > 0 &&
                            <li className="non-selectable">

                                <span className="service-list-icon">
                                    <span className="fas fa-calendar"></span>
                                </span>

                                <span className="service-list-name">
                                    Other appointments
                                </span>

                                <span className="service-list-price">
                                    <Money amount={linkedAppointmentsTotal} />
                                </span>

                            </li>
                        }

                        {/* Total */}
                        <li className="non-selectable total-row line-above">

                            <span className="service-list-icon">
                                <span className="fas fa-money-bill"></span>
                            </span>

                            <span className="service-list-name">
                                Total
                            </span>

                            <span className="service-list-price">
                                <Money amount={checkOut.total + linkedAppointmentsTotal} />
                            </span>

                        </li>

                        {/* Deposit / Paid */}
                        {checkOut.appointmentPayments.map((ap, index) =>
                            <li className="non-selectable total-row" key={index}>
                                <span className="service-list-icon">
                                </span>

                                <span className="service-list-name">
                                    {ap.isDeposit ?
                                        (ap.paymentMethodName ? `Deposit (${ap.paymentMethodName})` : 'Deposit') :
                                        (ap.paymentMethodName || 'Payment')
                                    }
                                </span>

                                <span className="service-list-price">
                                    <Money amount={-ap.amount} />
                                </span>
                            </li>
                        )}

                        {/* Remaining balance */}
                        <li className="non-selectable total-row">

                            <span className="service-list-icon">
                                <span className="fas fa-balance-scale"></span>
                            </span>

                            <span className="service-list-name">
                                Remaining Balance
                            </span>

                            <span className="service-list-price">
                                <Money amount={checkOut.balance} />
                            </span>

                        </li>

                        {/* Amount refunded */}
                        {checkOut.amountRefunded > 0 &&
                            <li className="non-selectable highlight-text">

                                <span className="service-list-icon">
                                </span>

                                <span className="service-list-name">
                                    Refunded
                                </span>

                                <span className="service-list-price">
                                    <Money amount={-checkOut.amountRefunded} />
                                </span>

                            </li>
                        }

                    </ul>

                </div>

            </div>

            {this.renderLoyaltyScheme()}

        </>);
    }
}

export default CheckOutPanel;