// Libs
import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

// Services & Helpers
import PromoCodeService from 'services/PromoCodeService';
import SalonService from 'services/SalonService';

// Components
import FloomlyComponent from 'components/FloomlyComponent';
import InfoBar from 'components/layout/InfoBar'
import Loader from 'components/reusable/Loader';
import SuperTable from 'components/reusable/SuperTable';
 
class SettingsPromoCodeList extends FloomlyComponent {
    constructor(props) {
        super(props);

        // Init state
        this.state = {
            isLoading: true,
            promoCodes: []
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const promoCodes = await PromoCodeService.list();
        this.setState({
            isLoading: false,
            promoCodes
        });
    }

    edit(id) {
        this.props.history.push('/settings/promo-code/' + id);
    }

    add() {
        this.props.history.push('/settings/promo-code/new');
    }

    //--------------------------------------------------------------------------------------------------------------------
    //  Render
    //--------------------------------------------------------------------------------------------------------------------

    renderInfoBar() {
        return (
            <InfoBar className="settings-info-bar" containerClassName="floating-on-mobile" sticky={true}>

                <div className="info-bar-panel-section info-bar-panel-section-text desktop-only">
                    Promo Codes
                </div>
                <div className="info-bar-panel-section ml-auto">
                    <button className="button" onClick={() => this.add()}>
                        <span className="fa fa-plus"></span>{' '}
                        Add New
                    </button>
                </div>

            </InfoBar>
        );
    }

    render() {
        const {
            isLoading,
            promoCodes
        } = this.state;
        const currency = SalonService.getCurrentCurrency();

        if (isLoading) {
            return (<Loader />);
        }

        return (<>
            {this.renderInfoBar()}

            <div className="settings-page-main">
                <div className="panel">
                    <div className="panel-body">
                        <SuperTable
                            className="settings-table"
                            rows={promoCodes}
                            keyAccessor={promoCode => promoCode.promoCodeID}
                            cols={{
                                isActive: {
                                    label: 'Active',
                                    renderCell: (colInfo, promoCode) =>
                                        <td>
                                            {promoCode.isActive ?
                                                <span className="fa fa-check"></span> :
                                                <span className="fa fa-times"></span>
                                            }
                                        </td>
                                },
                                name: { label: 'Name' },
                                code: { label: 'Code' },
                                discountAmount: {
                                    label: 'Discount',
                                    getValue: (colInfo, promoCode) => {
                                        switch (promoCode.discountType) {
                                            case 'percentage': return promoCode.discountAmount + '%'
                                            case 'fixed': return currency.symbol + promoCode.discountAmount
                                            case 'default' : return ''
                                        }
                                        return promoCode.discountAmount;
                                    }
                                },
                                latestBookingDate: {
                                    label: 'Appt booked by',
                                    getValue: (colInfo, promoCode) => {
                                        return promoCode.latestBookingDate ? moment(promoCode.latestBookingDate).format('DD/MM/YYYY') : ''
                                    }
                                },
                                latestAppointmentDate: {
                                    label: 'Appt taken place',
                                    getValue: (colInfo, promoCode) => {
                                        return promoCode.latestAppointmentDate ? moment(promoCode.latestAppointmentDate).format('DD/MM/YYYY') : ''
                                    }
                                }
                            }}
                            onClick={(promoCode, e) => this.edit(promoCode.promoCodeID)}
                        />
                    </div>
                </div>
            </div>
        </>);
    }

}
export default withRouter(SettingsPromoCodeList);